import httpClient from "./httpClient";

const EXPERT = "expert";
const SENIORITY_LEVEL = "seniority-level";
const EXPERT_SKILL = "expert-skill";
const SKILLS_WITH_GROUPS = "/skill";
const SKILL_LEVEL = "/skill-level";
const EXPERT_INDUSTRY = "/expert-industry";
const EXPERT_BUSINESS_MODEL = "/expert-business-model";
const EXPERT_CUSTOMER_GROUP = "/expert-customer-group";
const EXPERT_PROJECT_STATUS = "/expert-project-status";
const EXPERT_WORK_EXPERIENCE = "/expert-work-experience";
const EXPERT_STRONG_RELATION = "/expert-strong-relation";
const DEPARTMENT = "/department";
const EXPERT_NETWORK_STRENGTH = "/expert-network-strength";
const NETWORK_STRENGTH = "/network-strength";
const EXPERT_LANGUAGE = "/expert-language";
const LANGUAGE = "/language";
const LANGUAGE_LEVEL = "/language-level";
const EXPERT_AVAILABILITIES = "/expert-availabilities";
const EXPERT_RATES = "/expert-rates";

const getSeniorityLevels = () => httpClient.get(`${SENIORITY_LEVEL}`);

const createExpert = (payload) => httpClient.post(`${EXPERT}`, payload);

const updateExpertInfo = (payload) => httpClient.put(`${EXPERT}`, payload);

const updateExpertProfileImage = (payload) =>
  httpClient.post(`${EXPERT}/profile-image`, payload);

const getExpertSkills = (expertId) =>
  httpClient.get(`${EXPERT_SKILL}?expertId=${expertId}`);

const getAllSkillsWithGroups = () => httpClient.get(`${SKILLS_WITH_GROUPS}`);

const addExpertSkills = (payload) =>
  httpClient.post(`${EXPERT_SKILL}/add`, payload);

const deleteExpertSkill = (skillId) =>
  httpClient.delete(`${EXPERT_SKILL}/${skillId}`);

const getAllSkillLevels = () => httpClient.get(`${SKILL_LEVEL}`);

const getAllDepartments = () => httpClient.get(`${DEPARTMENT}`);

const getIndustriesOfExpert = (expertId) =>
  httpClient.get(`${EXPERT_INDUSTRY}?expertId=${expertId}`);

const getBusinessModelsOfExpert = (expertId) =>
  httpClient.get(`${EXPERT_BUSINESS_MODEL}?expertId=${expertId}`);

const getCustomerGroupsOfExpert = (expertId) =>
  httpClient.get(`${EXPERT_CUSTOMER_GROUP}?expertId=${expertId}`);

const deleteExpertIndustry = (industryId) =>
  httpClient.delete(`${EXPERT_INDUSTRY}/${industryId}`);

const addExpertIndustry = (industryId) =>
  httpClient.post(`${EXPERT_INDUSTRY}/${industryId}`);

const deleteExpertBusinessModel = (businessModelId) =>
  httpClient.delete(`${EXPERT_BUSINESS_MODEL}/${businessModelId}`);

const addExpertBusinessModel = (businessModelId) =>
  httpClient.post(`${EXPERT_BUSINESS_MODEL}/${businessModelId}`);

const deleteExpertCustomerGroup = (customerGroupId) =>
  httpClient.delete(`${EXPERT_CUSTOMER_GROUP}/${customerGroupId}`);

const addExpertCustomerGroup = (customerGroupId) =>
  httpClient.post(`${EXPERT_CUSTOMER_GROUP}/${customerGroupId}`);

const getExpertProjectStatuses = (expertId) =>
  httpClient.get(`${EXPERT_PROJECT_STATUS}?expertId=${expertId}`);

const addExpertProjectStatus = (projectStatusId) =>
  httpClient.post(`${EXPERT_PROJECT_STATUS}/${projectStatusId}`);

const deleteExpertProjectStatus = (projectStatusId) =>
  httpClient.delete(`${EXPERT_PROJECT_STATUS}/${projectStatusId}`);

const getCompaniesWhereExpertWorked = (expertId) =>
  httpClient.get(`${EXPERT_WORK_EXPERIENCE}?expertId=${expertId}`);

const addWorkExperienceOfExpert = (payload) =>
  httpClient.post(`${EXPERT_WORK_EXPERIENCE}`, payload);

const updateWorkExperienceOfExpert = (id, payload) =>
  httpClient.put(`${EXPERT_WORK_EXPERIENCE}/${id}`, payload);

const deleteWorkExperienceOfExpert = (companyId) =>
  httpClient.delete(`${EXPERT_WORK_EXPERIENCE}/${companyId}`);

const getStrongRelationsOfExpert = (expertId) =>
  httpClient.get(`${EXPERT_STRONG_RELATION}?expertId=${expertId}`);

const createExpertStrongRelation = (payload) =>
  httpClient.post(`${EXPERT_STRONG_RELATION}`, payload);

const updateExpertStrongRelation = (id, payload) =>
  httpClient.put(`${EXPERT_STRONG_RELATION}/${id}`, payload);

const deleteExpertStrongRelation = (id) =>
  httpClient.delete(`${EXPERT_STRONG_RELATION}/${id}`);

const getExpertNetworkStrengths = (expertId) =>
  httpClient.get(`${EXPERT_NETWORK_STRENGTH}?expertId=${expertId}`);

const createExpertNetworkStrength = (payload) =>
  httpClient.post(`${EXPERT_NETWORK_STRENGTH}`, payload);

const getNetworkStrength = () => httpClient.get(`${NETWORK_STRENGTH}`);

const getExpertLanguages = (expertId) =>
  httpClient.get(`${EXPERT_LANGUAGE}?expertId=${expertId}`);

const getLanguages = () => httpClient.get(`${LANGUAGE}`);

const addExpertLanguage = (payload) =>
  httpClient.post(`${EXPERT_LANGUAGE}/add`, payload);

const updateExpertLanguage = (payload) =>
  httpClient.post(`${EXPERT_LANGUAGE}/update`, payload);

const deleteExpertLanguage = (languageId) =>
  httpClient.delete(`${EXPERT_LANGUAGE}/${languageId}`);

const getLanguageLevels = () => httpClient.get(`${LANGUAGE_LEVEL}`);

const getExpertAvailabilitiesYearly = (expertId) =>
  httpClient.get(`${EXPERT_AVAILABILITIES}/yearly?expertId=${expertId}`);

const addExpertYearlyAvailability = (payload) =>
  httpClient.post(`${EXPERT_AVAILABILITIES}/yearly`, payload);

const getExpertAvailabilitiesHourly = (expertId) =>
  httpClient.get(`${EXPERT_AVAILABILITIES}/hourly?expertId=${expertId}`);

const addExpertHourlyAvailability = (payload) =>
  httpClient.post(`${EXPERT_AVAILABILITIES}/hourly`, payload);

const getExpertRates = (expertId) =>
  httpClient.get(`${EXPERT_RATES}?expertId=${expertId}`);

const saveExpertRates = (payload) =>
  httpClient.post(`${EXPERT_RATES}`, payload);

const saveExpertBaseRate = (payload) =>
  httpClient.post(`${EXPERT_RATES}/base-rate`, payload);

const getExpertInfo = (expertId) =>
  httpClient.get(`${EXPERT}/info?expertId=${expertId}`);

export {
  getSeniorityLevels,
  createExpert,
  updateExpertInfo,
  updateExpertProfileImage,
  getExpertSkills,
  getAllSkillsWithGroups,
  addExpertSkills,
  getAllSkillLevels,
  getIndustriesOfExpert,
  getBusinessModelsOfExpert,
  getCustomerGroupsOfExpert,
  deleteExpertIndustry,
  addExpertIndustry,
  deleteExpertBusinessModel,
  addExpertBusinessModel,
  addExpertCustomerGroup,
  deleteExpertCustomerGroup,
  getExpertProjectStatuses,
  addExpertProjectStatus,
  deleteExpertProjectStatus,
  getCompaniesWhereExpertWorked,
  addWorkExperienceOfExpert,
  updateWorkExperienceOfExpert,
  deleteWorkExperienceOfExpert,
  getStrongRelationsOfExpert,
  getAllDepartments,
  createExpertStrongRelation,
  updateExpertStrongRelation,
  deleteExpertStrongRelation,
  getExpertNetworkStrengths,
  getNetworkStrength,
  createExpertNetworkStrength,
  getExpertLanguages,
  getLanguages,
  addExpertLanguage,
  getLanguageLevels,
  updateExpertLanguage,
  getExpertAvailabilitiesYearly,
  addExpertYearlyAvailability,
  getExpertAvailabilitiesHourly,
  addExpertHourlyAvailability,
  getExpertRates,
  saveExpertRates,
  deleteExpertLanguage,
  deleteExpertSkill,
  getExpertInfo,
  saveExpertBaseRate,
};
