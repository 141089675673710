import * as firebase from "firebase/app";

let firebaseConfig;

if (
  process.env.VUE_APP_ENVIRONMENT === "local" ||
  process.env.VUE_APP_ENVIRONMENT === "dev"
) {
  firebaseConfig = {
    apiKey: "AIzaSyC43sd_ifrdqaq2979gA9PHhWpPD5nbal0",
    authDomain: "founders-dac5e.firebaseapp.com",
    projectId: "founders-dac5e",
    storageBucket: "founders-dac5e.appspot.com",
    messagingSenderId: "311697596703",
    appId: "1:311697596703:web:877237285817c611ff502a",
  };
} else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
  firebaseConfig = {
    apiKey: "AIzaSyBvYp2V8wN_K6yCUiS8Z6yCG5s2g12_oks",
    authDomain: "founders-staging.firebaseapp.com",
    projectId: "founders-staging",
    storageBucket: "founders-staging.appspot.com",
    messagingSenderId: "956277595111",
    appId: "1:956277595111:web:c1a074ebbd2a82a32ffb42",
  };
} else if (process.env.VUE_APP_ENVIRONMENT === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyBsUdXuG-8uXLxJllqxXAVj5Mjp8ESz9Ds",
    authDomain: "founders-production.firebaseapp.com",
    projectId: "founders-production",
    storageBucket: "founders-production.appspot.com",
    messagingSenderId: "203820499420",
    appId: "1:203820499420:web:0f89cdbab9231e8dec0f19",
  };
}

export default firebase.initializeApp(firebaseConfig);
