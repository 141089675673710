import {
  getAllSkillsWithGroups,
  getSeniorityLevels,
  getExpertSkills,
  getAllSkillLevels,
  getAllDepartments,
  getExpertProjectStatuses,
  getExpertRates,
  getCompaniesWhereExpertWorked,
} from "@/api/expert.api";

const state = {
  expert: {},
  expertWorkedCompanies: [],
  expertRates: [],
  seniorityLevels: [],
  skillsWithGroups: [],
  skillLevels: [],
  expertSkills: [],
  expertProjectStatuses: [],
  departments: [],
  dataLoading: false,
};

const getters = {
  getExpert(state) {
    return state.expert;
  },
  getExpertBaseRate(state) {
    return state.expert ? state.expert.baseRate : 0;
  },
  getExpertRates(state) {
    return state.expertRates;
  },
  getExpertProfileLink(state) {
    return state.expert ? state.expert.profileLink : null;
  },
  getExpertProfileImageUrl(state) {
    return state.expert ? state.expert.profileImageUrl : null;
  },
  getExpertProjectStatuses(state) {
    return state.expertProjectStatuses;
  },
  getExpertWorkedCompanies(state) {
    return state.expertWorkedCompanies;
  },
  getExpertCity(state) {
    return state.expert ? state.expert.city : null;
  },
  getExpertCompany(state) {
    return state.expert ? state.expert.companyName : null;
  },
  getSeniorityLevels(state) {
    return state.seniorityLevels;
  },
  getDepartments(state) {
    return state.departments;
  },
  getSkillsWithGroups(state) {
    return state.skillsWithGroups;
  },
  getExpertSkills(state) {
    return state.expertSkills;
  },
  isDataLoading(state) {
    return state.dataLoading;
  },
  getSkillLevel(state) {
    return state.skillLevels;
  },
};

const actions = {
  async fetchSeniorityLevels({ commit, state }) {
    if (state.seniorityLevels.length === 0) {
      const { data } = await getSeniorityLevels();
      commit("SET_SENIORITY_LEVELS", data.data);
    }
  },
  async fetchSkillsWithGroups({ commit }) {
    const { data } = await getAllSkillsWithGroups();
    commit("SET_SKILLS_WITH_GROUPS", data.data);
  },
  async fetchExpertSkills({ commit }, expertId) {
    const { data } = await getExpertSkills(expertId);
    commit("SET_EXPERT_DATA", data.data);
  },
  async fetchSkillLevels({ commit }) {
    const { data } = await getAllSkillLevels();
    commit("SET_SKILL_LEVELS", data.data);
  },
  async fetchDepartments({ commit }) {
    const { data } = await getAllDepartments();
    commit("SET_DEPARTMENTS", data.data);
  },
  mutateExpertSkills({ commit }, payload) {
    commit("MUTATE_EXPERT_SKILLS", payload);
  },
  setDataLoading({ commit }, isDataLoading) {
    commit("SET_DATA_LOADING", isDataLoading);
  },
  async fetchExpertRates({ state, commit }, forceFetch) {
    if (state.expert.id && (state.expertRates.length === 0 || forceFetch)) {
      try {
        const {
          data: { data },
        } = await getExpertRates();
        commit("SET_EXPERT_RATES", data);
      } catch {
        //
      }
    }
  },
  async fetchExpertProjectStatuses({ state, commit }, forceFetch) {
    if (
      state.expert.id &&
      (state.expertProjectStatuses.length === 0 || forceFetch)
    ) {
      try {
        const {
          data: { data },
        } = await getExpertProjectStatuses(state.expert.id);
        commit("EXPERT_PROJECT_STATUSES", data);
      } catch {
        //
      }
    }
  },
  async fetchExpertCompaniesWorkedWith({ state, commit }, forceFetch) {
    if (
      state.expert.id &&
      (state.expertWorkedCompanies.length === 0 || forceFetch)
    ) {
      try {
        const {
          data: { data },
        } = await getCompaniesWhereExpertWorked();
        commit("SET_EXPERT_WORKED_COMPANIES", data);
      } catch {
        //
      }
    }
  },
};

const mutations = {
  SET_SENIORITY_LEVELS(state, payload) {
    state.seniorityLevels = payload;
  },
  SET_SKILLS_WITH_GROUPS(state, payload) {
    state.skillsWithGroups = payload;
  },
  SET_DATA_LOADING(state, isDataLoading) {
    state.dataLoading = isDataLoading;
  },
  SET_EXPERT_DATA(state, payload) {
    state.expertSkills = payload;
  },
  SET_SKILL_LEVELS(state, payload) {
    state.skillLevels = payload;
  },
  SET_EXPERT_RATES(state, payload) {
    state.expertRates = payload;
  },
  SET_EXPERT_WORKED_COMPANIES(state, payload) {
    state.expertWorkedCompanies = payload;
  },
  MUTATE_EXPERT_SKILLS(state, payload) {
    state.expertSkills = [...state.expertSkills, payload];
  },
  SET_DEPARTMENTS(state, payload) {
    state.departments = payload;
  },
  SET_EXPERT(state, payload) {
    state.expert = payload;
  },
  EXPERT_PROJECT_STATUSES(state, payload) {
    state.expertProjectStatuses = payload;
  },
  CLEAR_EXPERT_STATE(state) {
    state.expert = {};
    state.expertWorkedCompanies = [];
    state.expertRates = [];
    state.seniorityLevels = [];
    state.skillsWithGroups = [];
    state.skillLevels = [];
    state.expertSkills = [];
    state.expertProjectStatuses = [];
    state.departments = [];
    state.dataLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
