import httpClient from "./httpClient";

const FOUNDER = "founder";
const CUSTOMER_GROUP = "customer-group";
const BUSINESS_MODALS = "business-model";
const PITCH_UPLOADS = "pitch-uploads";
const REVENUE_SHARE = "/revenue-share";
const PROJECT_DEAL_TERMS = "/project-deal-terms";
const PROJECT_HIGHLIGHTS = "/project-highlights";
const PROJECT_THRESHOLD = "/project-threshold";
const PERFORMANCE_INDICATOR = "/performance-indicator";
const PROJECT_TEAM_MEMBERS = "/project-team-members";
const PROJECT_NEEDS = "/project-needs";
const PRIORITY_LEVEL = "/priority-level";
const REQUIRED_UPLOADS = "/required-uploads";

const getCustomerGroups = () => httpClient.get(`${CUSTOMER_GROUP}`);

const getBusinessModals = () => httpClient.get(`${BUSINESS_MODALS}`);

const createFounder = (payload) => httpClient.post(`${FOUNDER}`, payload);

const updateFounderProfileImage = (payload) =>
  httpClient.post(`${FOUNDER}/profile-image`, payload);

const uploadLogo = (formData) =>
  httpClient.post(`${PITCH_UPLOADS}/logo`, formData);

const deleteLogo = (attachmentType) =>
  httpClient.delete(`${PITCH_UPLOADS}/logo/${attachmentType}`);

const getLogos = (founderId) =>
  httpClient.get(`${PITCH_UPLOADS}/logo?founderId=${founderId}`);

const getRevenueShares = () => httpClient.get(`${REVENUE_SHARE}`);

const getProjectDealTerms = (founderId) =>
  httpClient.get(`${PROJECT_DEAL_TERMS}?founderId=${founderId}`);

const getProjectHighlights = (founderId) =>
  httpClient.get(`${PROJECT_HIGHLIGHTS}?founderId=${founderId}`);

const createProjectHighlight = (highlight) =>
  httpClient.post(`${PROJECT_HIGHLIGHTS}`, { highlight });

const updateBasicRevenueShare = (revenueShareId) =>
  httpClient.post(
    `${PROJECT_DEAL_TERMS}/basic-revenue-share/${revenueShareId}`
  );

const updatePercentageRevenueShare = (percentageRevenueShare) =>
  httpClient.post(`${PROJECT_DEAL_TERMS}/percentage-revenue-share`, {
    percentageRevenueShare,
  });

const getProjectThresholdItems = () => httpClient.get(`${PROJECT_THRESHOLD}`);

const updateFreeThreshold = (thresholdItemId) =>
  httpClient.post(`${PROJECT_DEAL_TERMS}/free-threshold/${thresholdItemId}`);

const updateEquityShare = (percentageEquityShare) =>
  httpClient.post(`${PROJECT_DEAL_TERMS}/percentage-equity-share`, {
    percentageEquityShare,
  });

const getAllPerformanceIndicators = () =>
  httpClient.get(`${PERFORMANCE_INDICATOR}`);

const getPerformanceIndicatorsOfLoggedInFounder = () =>
  httpClient.get(`${PERFORMANCE_INDICATOR}/me`);

const addPerformanceIndicatorToProject = (indicatorId) =>
  httpClient.post(`${PERFORMANCE_INDICATOR}/${indicatorId}`);

const removePerformanceIndicatorFromProject = (indicatorId) =>
  httpClient.delete(`${PERFORMANCE_INDICATOR}/${indicatorId}`);

const removeProjectHighlight = (projectHighlightId) =>
  httpClient.delete(`${PROJECT_HIGHLIGHTS}/${projectHighlightId}`);

const getProjectTeamMembers = () => httpClient.get(`${PROJECT_TEAM_MEMBERS}`);

const addProjectTeamMembers = (formData) =>
  httpClient.post(`${PROJECT_TEAM_MEMBERS}`, formData);

const removeProjectTeamMember = (id) =>
  httpClient.delete(`${PROJECT_TEAM_MEMBERS}/${id}`);
const getProjectUploads = () => httpClient.get(`${PITCH_UPLOADS}/required`);

const uploadProjectUploads = (formData) =>
  httpClient.post(`${PITCH_UPLOADS}/required`, formData);

const deleteProjectUpload = (attachmentType) =>
  httpClient.delete(`${PITCH_UPLOADS}/required/${attachmentType}`);

const getAllProjectNeedsOfProject = (founderId) =>
  httpClient.get(`${PROJECT_NEEDS}?founderId=${founderId}`);

const createProjectNeed = (payload) =>
  httpClient.post(`${PROJECT_NEEDS}`, payload);

const updateProjectNeed = (payload, projectNeedId) =>
  httpClient.put(`${PROJECT_NEEDS}/${projectNeedId}`, payload);

const deleteProjectNeed = (projectNeedId) =>
  httpClient.delete(`${PROJECT_NEEDS}/${projectNeedId}`);

const getAllPriorityLevels = () => httpClient.get(`${PRIORITY_LEVEL}`);

const getProjectNeedsStats = () => httpClient.get(`${PROJECT_NEEDS}/stats`);

const getProjectNeedsWithPercentage = (founderId) =>
  httpClient.get(`${PROJECT_NEEDS}/percentage?founderId=${founderId}`);

const getAllRequiredUploads = (founderId) =>
  httpClient.get(`${REQUIRED_UPLOADS}?founderId=${founderId}`);

const getFounderCompanyName = (founderId) =>
  httpClient.get(`${FOUNDER}/company?founderId=${founderId}`);

const getBusinessModelsOfFounder = (founderId) =>
  httpClient.get(`${FOUNDER}/business-model?founderId=${founderId}`);

const getCustomerGroupsOfFounder = (founderId) =>
  httpClient.get(`${FOUNDER}/customer-group?founderId=${founderId}`);

const getIndustriesOfFounder = (founderId) =>
  httpClient.get(`${FOUNDER}/industry?founderId=${founderId}`);

const getFounderInfo = () => httpClient.get(`${FOUNDER}/sign-up-info`);

const getExpertsFromRocketReach = (skill) =>
  httpClient.get(`rocketReach/search?current_title=${skill}`);

const updateFounderInfo = (payload) => httpClient.put(`${FOUNDER}`, payload);

const saveRocketReachExpert = (expertId, expert) =>
  httpClient.post(`${FOUNDER}/save-expert`, {
    expertId: expertId,
    expert: expert,
  });

const investInProject = (formData) =>
  httpClient.post(`/invest/hubspot`, formData);

export {
  getCustomerGroups,
  getBusinessModals,
  createFounder,
  updateFounderProfileImage,
  uploadLogo,
  deleteLogo,
  getLogos,
  getRevenueShares,
  getProjectDealTerms,
  getProjectHighlights,
  createProjectHighlight,
  updateBasicRevenueShare,
  updatePercentageRevenueShare,
  getProjectThresholdItems,
  updateFreeThreshold,
  updateEquityShare,
  getAllPerformanceIndicators,
  getPerformanceIndicatorsOfLoggedInFounder,
  addPerformanceIndicatorToProject,
  removePerformanceIndicatorFromProject,
  removeProjectHighlight,
  getProjectUploads,
  uploadProjectUploads,
  deleteProjectUpload,
  getProjectTeamMembers,
  addProjectTeamMembers,
  removeProjectTeamMember,
  getAllProjectNeedsOfProject,
  createProjectNeed,
  updateProjectNeed,
  deleteProjectNeed,
  getAllPriorityLevels,
  getProjectNeedsStats,
  getProjectNeedsWithPercentage,
  getAllRequiredUploads,
  getFounderCompanyName,
  getBusinessModelsOfFounder,
  getCustomerGroupsOfFounder,
  getIndustriesOfFounder,
  getFounderInfo,
  updateFounderInfo,
  getExpertsFromRocketReach,
  saveRocketReachExpert,
  investInProject
};
