import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store/store.js";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import firebase from "@/common/plugins/firebase";

//imports for app initialization
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

let app;

onAuthStateChanged(getAuth(), () => {
  if (!app) {
    app = createApp(App);

    app.use(firebase);
    app.use(store);
    app.use(router);
    app.use(ElementPlus);

    initApexCharts(app);
    initInlineSvg(app);
    initVeeValidate();

    app.use(i18n);

    app.mount("#app");
  }
});
