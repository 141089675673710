import {
  getBusinessModals,
  getCustomerGroups,
  getProjectNeedsStats,
} from "@/api/founder.api";

const state = {
  founder: {},
  customerGroups: [],
  businessModels: [],
  projectNeedsStats: {
    totalNeeds: null,
    totalNeedsBudget: null,
    mustHaveNeedsBudget: null,
    importantNeedsBudget: null,
  },
};

const getters = {
  getFounder(state) {
    return state.founder;
  },
  getFounderProfileImageUrl(state) {
    return state.founder ? state.founder.profileImageUrl : null;
  },
  getUserRole(state) {
    return state.role;
  },
  getCustomerGroups(state) {
    return state.customerGroups;
  },
  getBusinessModals(state) {
    return state.businessModals;
  },
  getProjectNeedsStats(state) {
    return state.projectNeedsStats;
  },
};

const actions = {
  async fetchCustomerGroups({ commit }) {
    const { data } = await getCustomerGroups();
    commit("SET_CUSTOMER_GROUPS", data.data);
  },
  async fetchBusinessModals({ commit }) {
    const { data } = await getBusinessModals();
    commit("SET_BUSINESS_MODELS", data.data);
  },
  async fetchProjectNeedsStats({ commit }) {
    const { data } = await getProjectNeedsStats();
    commit("SET_PROJECT_NEEDS_STATS", data.data);
  },
};

const mutations = {
  SET_FOUNDER(state, payload) {
    state.founder = payload;
  },
  SET_CUSTOMER_GROUPS(state, payload) {
    state.customerGroups = payload;
  },
  SET_BUSINESS_MODELS(state, payload) {
    state.businessModals = payload;
  },
  SET_PROJECT_NEEDS_STATS(state, payload) {
    state.projectNeedsStats = payload;
  },
  CLEAR_FOUNDER_STATE(state) {
    state.founder = {};
    state.customerGroups = [];
    state.businessModels = [];
    state.projectNeedsStats = {
      totalNeeds: null,
      totalNeedsBudget: null,
      mustHaveNeedsBudget: null,
      importantNeedsBudget: null,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
