import {
  getAllIndustries,
  getAllProjectStatuses,
  getLoggedInUserWithRelations,
} from "@/api/user.api";

const state = {
  role: "expert",
  userId: null,
  allProjectStatuses: [],
  allIndustries: [],
  user: {},
};

const getters = {
  getUserRole(state) {
    return state.role;
  },
  getAllProjectStatuses(state) {
    return state.allProjectStatuses;
  },
  getAllIndustries(state) {
    return state.allIndustries;
  },
  getUserId(state) {
    return state.userId;
  },
  getExpertId(state) {
    return state.user.expert ? state.user.expert.id : undefined;
  },
  getUser(state) {
    return state.user;
  },
};

const actions = {
  setUserRole({ commit }, role) {
    commit("SET_USER_ROLE", role);
  },
  setUser({ commit }, user) {
    commit("SET_USER", user);
    if (user.expert) {
      commit("ExpertModule/SET_EXPERT", user.expert, { root: true });
    }
    if (user.founder) {
      commit("FounderModule/SET_FOUNDER", user.founder, { root: true });
    }
  },
  setUserId({ commit }, userId) {
    commit("SET_USER_ID", userId);
  },
  async fetchUserAndSave({ dispatch }) {
    try {
      const {
        data: { data },
      } = await getLoggedInUserWithRelations();
      await dispatch("setUser", {});
      await dispatch("setUser", data);
    } catch (e) {
      //
    }
  },
  async fetchUserAndSaveIfNotExist({ state, dispatch }) {
    if (!state.user.id) {
      await dispatch("fetchUserAndSave");
    }
  },
  async fetchAllProjectStatuses({ commit, state }) {
    if (state.allProjectStatuses.length === 0) {
      const { data } = await getAllProjectStatuses();
      commit("SET_ALL_PROJECT_STATUSES", data.data);
    }
  },
  async fetchAllIndustries({ commit, state }) {
    if (state.allIndustries.length === 0) {
      const { data } = await getAllIndustries();
      commit("SET_ALL_INDUSTRIES", data.data);
    }
  },
};

const mutations = {
  SET_USER_ROLE(state, role) {
    state.role = role;
  },
  SET_ALL_PROJECT_STATUSES(state, payload) {
    state.allProjectStatuses = payload;
  },
  SET_ALL_INDUSTRIES(state, payload) {
    state.allIndustries = payload;
  },
  SET_USER_ID(state, userId) {
    state.userId = userId;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  CLEAR_USER_STORE(state) {
    state.user = {};
    state.userId = null;
    state.allProjectStatuses = [];
    state.allIndustries = [];
    state.user = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
