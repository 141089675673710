
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      window.addEventListener("storage", function (event) {
        if (event.key == "logout-event") {
          const auth = getAuth();
          signOut(auth)
            .then(() => {
              store.commit("UserModule/CLEAR_USER_STORE");
              store.commit("ExpertModule/CLEAR_EXPERT_STATE");
              store.commit("FounderModule/CLEAR_FOUNDER_STATE");
              router.push({ name: "sign-in" });
              window.location.reload();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });

      window.addEventListener("storage", function (event) {
        if (event.key == "login-event") {
          window.location.reload();
        }
      });

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
