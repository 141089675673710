import httpClient from "./httpClient";

const USER = "/user";
const PROJECT_STATUS = "/project-status";
const INDUSTRY = "/industry";
const BUSINESS_MODEL = "/business-model";
const CUSTOMER_GROUP = "/customer-group";

const getUser = (uuid) => httpClient.get(`${USER}?uuid=${uuid}`);

const getLoggedInUserWithRelations = () => httpClient.get(`${USER}/logged-in`);

const getAllProjectStatuses = () => httpClient.get(`${PROJECT_STATUS}`);

const getAllIndustries = () => httpClient.get(`${INDUSTRY}`);

const getAllBusinessModels = () => httpClient.get(`${BUSINESS_MODEL}`);

const getAllCustomerGroups = () => httpClient.get(`${CUSTOMER_GROUP}`);

export {
  getUser,
  getAllProjectStatuses,
  getAllIndustries,
  getLoggedInUserWithRelations,
  getAllBusinessModels,
  getAllCustomerGroups,
};
