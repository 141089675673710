import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "../store/modules/AuthModule";
import BodyModule from "../store/modules/BodyModule";
import BreadcrumbsModule from "../store/modules/BreadcrumbsModule";
import ConfigModule from "../store/modules/ConfigModule";
import UserModule from "../store/modules/User.store.js";
import FounderModule from "../store/modules/Founder.store.js";
import ExpertModule from "../store/modules/Expert.store.js";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    FounderModule,
    ExpertModule,
  },
});

export default store;
