import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getAuth } from "firebase/auth";
import store from "@/store/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      //Start Admin Temp Routes
      {
        path: "/eexperts",
        name: "Experts",
        component: () => import("@/admin/views/Experts.vue"),
        meta: {
          requiresAuth: true,
          accountAccess: ["founder", "expert"],
        },
      },
      {
        path: "/pprojects",
        name: "Projects",
        component: () => import("@/admin/views/Projects.vue"),
        meta: {
          requiresAuth: true,
          accountAccess: ["founder", "expert"],
        },
      },
      //End Admin Temp Routes
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          requiresAuth: true,
          accountAccess: ["founder", "expert"],
        },
      },
      {
        path: "/pitch",
        name: "FounderPitch",
        component: () => import("@/founder/views/FounderPitch.vue"),
        redirect: "/pitch",
        meta: {
          requiresAuth: true,
          accountAccess: ["founder"],
        },
        children: [
          {
            path: "/pitch",
            name: "CreatePitch",
            component: () => import("@/founder/views/CreatePitch.vue"),
            meta: {
              requiresAuth: true,
              accountAccess: ["founder"],
            },
          },
        ],
      },
      {
        path: "/founder-info",
        name: "FounderSignUpInfo",
        component: () => import("@/founder/views/FounderSignUpInfo.vue"),
        meta: {
          requiresAuth: true,
          accountAccess: ["founder"],
        },
      },
      // {
      //   path: "/pitch",
      //   name: "PublicPitch",
      //   component: () => import("@/founder/components/PublicPitch.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: "/needs",
        name: "Needs",
        component: () => import("@/founder/views/Needs.vue"),
        meta: {
          requiresAuth: true,
          accountAccess: ["founder"],
        },
      },
      {
        path: "/profile",
        name: "ExpertProfile",
        component: () => import("@/expert/views/ExpertProfile.vue"),
        redirect: "/profile/overview",
        meta: {
          requiresAuth: true,
          accountAccess: ["expert"],
        },
        children: [
          {
            path: "/profile/overview",
            name: "ExpertProfileDetails",
            component: () => import("@/expert/views/ExpertProfileDetails.vue"),
            meta: {
              requiresAuth: true,
              accountAccess: ["expert"],
            },
          },
          {
            path: "/profile/info",
            name: "SignupInfo",
            component: () => import("@/expert/views/ExpertSignUpInfo.vue"),
            meta: {
              requiresAuth: true,
              accountAccess: ["expert"],
            },
          },
          // {
          //   path: "/profile/:expertId",
          //   name: "ExpertProfilePublic",
          //   component: () => import("@/expert/views/ExpertProfileDetails.vue"),
          //   meta: {
          //     requiresAuth: true,
          //   },
          // },
        ],
      },
    ],
  },
  // SignUp Journey Routes
  {
    path: "/account-selection",
    name: "AccountSelection",
    component: () => import("@/user-journey/components/AccountSelection.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/expert-wizard",
    name: "Expert Wizard",
    component: () => import("@/user-journey/components/ExpertWizard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/founder-wizard",
    name: "Founder Wizard",
    component: () => import("@/user-journey/components/FounderWizard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // End SignUp Journey Routes
  {
    path: "/",
    component: () => import("@/auth/views/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/auth/components/SignIn.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/auth/components/SignUp.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/auth/components/PasswordReset.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/terms-conditions",
        name: "TermsAndConditions",
        component: () => import("@/auth/components/TermsAndConditions.vue"),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/auth/components/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/auth/components/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const userCanVisitRouteBasedOnAccountType = async (to) => {
  const expert = await store.getters["ExpertModule/getExpert"];
  const founder = await store.getters["FounderModule/getFounder"];
  const userAccountType = expert.id ? "expert" : founder.id ? "founder" : null;
  return !!(
    userAccountType &&
    to.meta.accountAccess &&
    to.meta.accountAccess.includes(userAccountType)
  );
};

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const currentUser = getAuth().currentUser;
  const requiresAuth = to.meta.requiresAuth;
  if (currentUser && !requiresAuth) {
    next("/dashboard");
  } else if (!currentUser && requiresAuth) {
    next("/sign-in");
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
